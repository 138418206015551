import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const styles = css`
  .testimonials {
    background: linear-gradient(145deg, #012b, #0126, #012b);
    max-width: min(1200px, 100vw - 2rem);
    margin: 0 auto;
    padding: 0.6rem;
    border-radius: 1rem;
    box-shadow: rgba(255, 255, 255, 0.25) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.6) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.7) 0px 30px 60px -30px, rgba(103, 178, 111, 0.6) -10px 10px 60px -10px;
  }
  .innertestimonials {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    position: relative;
    padding: 1.5rem 1rem;
    border-radius: 0.6rem;
    background: repeating-conic-gradient(#0004 0 0.0001%, #0000 0 0.0002%), #0128;
    background: #0128;
    box-shadow: rgba(255, 255, 255, 0.6) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.7) 0px 5px 10px;
    color: #fff;
    @media (min-width: 1000px) {
      flex-direction: row;
    }

    & .divider {
      align-self: center;
      display: block;
      width: 100px;
      height: 1px;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 70px 15px;

      @media (min-width: 1000px) {
        height: 100px;
        width: 1px;
      }
    }
  }

  .smalltestimonial {
    flex: 1 1 20%;
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1000px) {
      align-items: flex-start;
      flex-direction: row;
    }

    & div.image {
      flex: 0 0 60px;
      aspect-ratio: 1;
      height: 60px;
      border-radius: 100%;
      box-shadow: rgba(255, 255, 255, 0.8) 0px -1px 0px 0px, rgba(50, 50, 93, 0.7) 0px 15px 30px;

      & img {
        border-radius: 100%;
      }
    }

    & div.quote {
      font-size: 1rem;
      text-align: left;

      & q,
      & > div {
        display: block;
      }

      & > div {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.875rem;
        margin-top: 1rem;
        & span {
          color: rgb(88, 185, 147)
        }
      }
    }
  }
`;

function Testimonals(props) {
  return (
    <div className={styles.testimonials}>
      <div className={styles.innertestimonials}>
        <div className={styles.smalltestimonial}>
          <div className={styles.quote}>
            <q>
              This thing is FAST and the synchronized scrolling works amazingly. <b>Prepare to be impressed</b>.
            </q>
            <div>
              <div className={styles.image}>
                <StaticImage src="../../../assets/images/vivian.jpg" width={60} height={60} alt="" />
              </div>
              <span>
                <b>Vivian Guillen</b> - <small>Frontend Dev &amp; Curator at Tailwind Weekly</small>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.smalltestimonial}>
          <div className={styles.quote}>
            <q>
              Polypane is really saving my bacon. <b>Kilian knocked it out of the park</b>, this is my new favorite
              development tool.
            </q>
            <div>
              <div className={styles.image}>
                <StaticImage src="../../../assets/images/testimonials/stolinski.jpg" width={60} height={60} alt="" />
              </div>
              <span>
                <b>Scott Tolinski</b> - <small>Syntax.fm host, Level Up Tutorials</small>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.smalltestimonial}>
          <div className={styles.quote}>
            <q>
              Polypane helped me see a spacing issue between two breakpoints that I wasn’t aware of. <b>Love it 💯</b>
            </q>
            <div>
              <div className={styles.image}>
                <StaticImage src="../../../assets/images/sara.jpg" width={60} height={60} alt="" />
              </div>
              <span>
                <b>Sara Soueidan</b> - <small>Front-end UI engineer, Speaker/Trainer</small>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.smalltestimonial}>
          <div className={styles.quote}>
            <q>
              I've started playing around with Polypane and 🤯.{' '}
              <strong>It has awesome debugging tools, I'm really impressed.</strong>
            </q>
            <div>
              <div className={styles.image}>
                <StaticImage src="../../../assets/images/kevin.jpg" width={60} height={60} alt="" />
              </div>
              <span>
                <b>Kevin Powell</b> - <small>CSS Evangelist</small>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonals;
